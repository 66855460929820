import { observable, makeObservable, action, computed, toJS } from "mobx";
import axios from "axios";
import { PayabliStorageManager } from "../api/localStorageManager";

class VirtualTerminalStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  paymentMethod = "";

  @observable
  savedPaymentMethodSelected = "";

  @observable
  defaultPaymentMethodActiveKey = "4";

  @observable
  paymentMethodSavedPosition = "2";

  @observable
  paymentDataStoredId = null;

  @observable
  disableSearchCustomer = false;

  @observable
  disableSearchVendor = false;

  @observable
  credentials = [];

  @observable
  paypointStatus = 0;

  @observable
  customers = [];

  @observable
  vendors = [];

  @observable
  lineItems = [];

  @observable
  customerSelected = {};

  @observable
  vendorSelected = {};

  @observable
  lineItemSelected = {};

  @observable
  operation = "sale";

  @observable
  currency = "USD";

  @observable
  paymentPageErrors = {
    autopayStartDateError: false,
    autopayFrequencyError: false,
    autopayFinishError: false,

    paymentMethodsAchAccountHolderNameError: false,
    paymentMethodsAchAccountTypeError: false,
    paymentMethodsAchRoutingError: false,
    paymentMethodsAchAccountError: false,

    paymentMethodsCardNumberError: false,
    paymentMethodsCardExpirationDateError: false,
    paymentMethodsCardCvvError: false,
    paymentMethodsCardZipcodeError: false,
    paymentMethodsCardHolderNameError: false,

    credentialsMaxMinTicketError: false,

    customerError: false,
  };

  @observable
  paymentPage = {
    amount: {
      categories: [
        {
          name: "amountDefaultPayabliApp",
          type: "customer",
          label: "Amount you wish to charge",
          value: "0.00",
          description: "",
          quantity: 1,
          showDescription: true,
        },
      ],
    },
    autopay: {
      finish: {
        untilCancelled: true,
        calendar: false,
      },
      frequency: {
        onetime: true,
        weekly: true,
        every2Weeks: true,
        every6Months: true,
        monthly: true,
        every3Months: true,
        annually: true,
      },
      startDate: "",
      enabled: false,
      frequencySelected: "",
      finishSelected: "",
    },
    paymentMethods: {
      methods: {
        visa: true,
        mastercard: true,
        discover: true,
        amex: true,
        eCheck: true,
        check: true,
        cash: true,
        applePay: true,
        googlePay: true,
        payPal: true,
        bitPay: true,
      },
      savePaymentMethodForFuture: true,
      cardHolderName: "",
    },
    payor: {
      invoiceNumber: {
        label: "Invoice #",
        validation: "numbers",
        value: "",
      },
    },
    notes: {
      placeholder: "Please enter notes",
      value: "",
    },
  };

  paymentPageTemplate = {
    amount: {
      categories: [
        {
          name: "amountDefaultPayabliApp",
          type: "customer",
          label: "Amount you wish to charge",
          value: "0.00",
          description: "",
          quantity: 1,
          showDescription: true,
        },
      ],
    },
    autopay: {
      finish: {
        untilCancelled: true,
        calendar: false,
      },
      frequency: {
        onetime: true,
        weekly: true,
        every2Weeks: true,
        every6Months: true,
        monthly: true,
        every3Months: true,
        annually: true,
      },
      startDate: "",
      enabled: false,
      frequencySelected: "",
      finishSelected: "",
    },
    paymentMethods: {
      methods: {
        visa: true,
        mastercard: true,
        discover: true,
        amex: true,
        eCheck: true,
        check: true,
        cash: true,
        applePay: true,
        googlePay: true,
        payPal: true,
        bitPay: true,
      },
      savePaymentMethodForFuture: true,
      cardHolderName: "",
    },
    payor: {
      invoiceNumber: {
        label: "Invoice #",
        validation: "numbers",
        value: "",
      },
    },
    notes: {
      placeholder: "Please enter additional information...",
      value: "",
    },
  };

  @action
  clearACHForm() {
    this.paymentPage.paymentMethods.achAccountHolderName = "";
    this.paymentPage.paymentMethods.achAccountType = "";
    this.paymentPage.paymentMethods.achRouting = "";
    this.paymentPage.paymentMethods.achAccount = "";
  }

  @action
  clearCCForm() {
    this.paymentPage.paymentMethods.cardNumber = "";
    this.paymentPage.paymentMethods.cardExpirationDate = "";
    this.paymentPage.paymentMethods.cardCvv = "";
    this.paymentPage.paymentMethods.cardZipcode = "";
    this.paymentPage.paymentMethods.cardHolderName = "";
  }

  @action
  clearCheckForm() {
    this.paymentPage.paymentMethods.checkAccountHolderName = "";
    this.paymentPage.paymentMethods.checkNumber = "";
  }

  getFieldOrder(items) {
    var max = 0;
    items.forEach(function (item, index) {
      if (parseInt(item.order) > parseInt(max)) {
        max = parseInt(item.order);
      }
    });
    return max + 1;
  }

  @action
  removeAmountCategory(event) {
    let idRemove = event.target.id.replace("categoryRemove", "");
    this.paymentPage.amount.categories.splice(idRemove, 1);
  }

  @action
  removeItemCategory(position) {
    this.paymentPage.amount.categories.splice(position, 1);
  }

  @action
  editItemCategory(item, position) {
    this.paymentPage.amount.categories.splice(position, 1, item);
  }

  @action
  setPaymentMethod(method) {
    this.paymentMethod = method;
  }

  @action
  setDefaultPaymentMethodActiveKey(key) {
    this.defaultPaymentMethodActiveKey = key;
  }

  @action
  setSavedPaymentMethodSelected(data) {
    this.savedPaymentMethodSelected = data;
  }

  @action
  savePaymentMethodForFuture(value) {
    this.paymentPage.paymentMethods.savePaymentMethodForFuture = value;
  }

  @action
  handleCategoryQtySubstract(event) {
    var category = event.target.id.replace("amountCategoryQtySubstract", "");
    if (this.paymentPage.amount.categories[category].quantity > 1)
      this.paymentPage.amount.categories[category].quantity--;
  }

  @action
  handlePayorTextChange(field, value) {
    this.paymentPage.payor[field].value = value;
  }

  @action
  handleNotesTextChange(value) {
    this.paymentPage.notes.value = value;
  }

  @action
  handleCategoryQtyAdd(event) {
    var category = event.target.id.replace("amountCategoryQtyAdd", "");
    this.paymentPage.amount.categories[category].quantity++;
  }

  @action
  handleItemQtySubstract(position) {
    if (
      this.paymentPage.amount.categories[position] !== null &&
      this.paymentPage.amount.categories[position] !== undefined
    ) {
      if (this.paymentPage.amount.categories[position].quantity > 1)
        this.paymentPage.amount.categories[position].quantity--;
    }
  }

  @action
  handleItemQtyAdd(position) {
    if (
      this.paymentPage.amount.categories[position] !== null &&
      this.paymentPage.amount.categories[position] !== undefined
    ) {
      this.paymentPage.amount.categories[position].quantity++;
    }
  }

  @action
  handleItemQtyInput(event) {
    var position = event.target.id.replace("amountItemQtyInput", "");
    if (
      this.paymentPage.amount.categories[position] !== null &&
      this.paymentPage.amount.categories[position] !== undefined
    ) {
      event.target.value > 1
        ? (this.paymentPage.amount.categories[position].quantity =
            event.target.value)
        : (this.paymentPage.amount.categories[position].quantity = 1);
    }
  }

  @action
  handleUpValueInput(event, position) {
    if (
      event.value > 0 &&
      this.paymentPage.amount.categories.length > position
    ) {
      this.paymentPage.amount.categories[position].value = event.value;
    }
  }

  @action
  handleCategoryQtyInput(event) {
    var position = event.target.id.replace("amountCategoryQtyInput", "");
    event.target.value > 1
      ? (this.paymentPage.amount.categories[position].quantity =
          event.target.value)
      : (this.paymentPage.amount.categories[position].quantity = 1);
  }

  @action
  addNewLineItemField(item) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    return axios
      .post(
        process.env.REACT_APP_URL_API + "LineItem/" + entryPoint,
        {
          itemProductName: item.label,
          itemDescription: item.description,
          itemCost: Number(parseFloat(item.value).toFixed(2)),
          itemQty: 1,
        },
        {
          headers: { requestToken: encryptStorage.getItem("pToken") },
        }
      )
      .then((res) => {
        if (
          this.paymentPage.amount.categories.length === 1 &&
          this.paymentPage.amount.categories[0].value === "0.00"
        ) {
          this.cleanCategories();
        }
        item.type = "quantity";
        this.paymentPage.amount.categories.push(item);
      })
      .catch((error) => {
        throw error;
      });
  }

  @action
  cleanCategories() {
    this.paymentPage.amount.categories = [];
  }

  @action
  handleCategoryTextChangeMask(field, key, value) {
    this.paymentPage.amount.categories[field][key] = value;
  }

  @action
  disableAutopay(checked) {
    this.paymentPage.autopay.enabled = checked;
  }

  @action
  handleTextChangeACH(field, value) {
    this.paymentPage.paymentMethods[field] = value;
  }

  @computed
  get amountCategories() {
    return this.paymentPage.amount.categories;
  }

  @action
  setAmountCategories(categories) {
    this.paymentPage.amount.categories = categories;
  }

  @action
  setPaymentMethodSavedPosition(data) {
    this.paymentMethodSavedPosition = data;
  }

  @action
  setPaymentMethodSaved(paymentMethodSaved) {
    this.paymentPage.paymentMethods.paymentMethodSaved = paymentMethodSaved;
  }

  @action
  setPaymentDataStoredId(storedId) {
    this.paymentDataStoredId = storedId;
  }

  @computed
  get isAutopay() {
    return this.paymentPage.autopay.enabled;
  }

  @computed
  get hasCards() {
    return (
      this.paymentPage.paymentMethods.methods.visa ||
      this.paymentPage.paymentMethods.methods.mastercard ||
      this.paymentPage.paymentMethods.methods.discover ||
      this.paymentPage.paymentMethods.methods.amex
    );
  }

  @computed
  get hasCCError() {
    return (
      this.paymentPageErrors.paymentMethodsCardNumberError ||
      this.paymentPageErrors.paymentMethodsCardExpirationDateError ||
      this.paymentPageErrors.paymentMethodsCardCvvError ||
      this.paymentPageErrors.paymentMethodsCardZipcodeError
    );
  }

  @computed
  get hasECheck() {
    return this.paymentPage.paymentMethods.methods.eCheck;
  }

  @computed
  get hasCheck() {
    return this.paymentPage.paymentMethods.methods.check;
  }

  @computed
  get hasCash() {
    return this.paymentPage.paymentMethods.methods.cash;
  }

  @computed
  get getPaymentPageErrors() {
    return this.paymentPageErrors;
  }

  @computed
  get hasMoneyOutCredentials() {
    let credentialsPermissions = this.getCredentialsPermissions;
    let keys = Object.keys(credentialsPermissions);
    let hasCredentials = false;
    for (var i = 0; i < keys.length; i++) {
      if (credentialsPermissions[keys[i]].moneyout === true) {
        return true;
      }
    }
    return hasCredentials;
  }

  @action
  setOperation(value) {
    this.operation = value;
  }

  @action
  setPaymentPageError(field, value) {
    this.paymentPageErrors[field] = value;
  }

  @action
  resetPaymentPage() {
    this.paymentPage = this.paymentPageTemplate;
    this.savedPaymentMethodSelected = "";
    this.lineItemSelected = {};
  }

  @action
  clearPaymentPageError() {
    this.paymentPageErrors = {
      autopayStartDateError: false,
      autopayFrequencyError: false,
      autopayFinishError: false,

      paymentMethodsAchAccountHolderNameError: false,
      paymentMethodsAchAccountTypeError: false,
      paymentMethodsAchRoutingError: false,
      paymentMethodsAchAccountError: false,

      paymentMethodsCardNumberError: false,
      paymentMethodsCardExpirationDateError: false,
      paymentMethodsCardCvvError: false,
      paymentMethodsCardZipcodeError: false,
      paymentMethodsCardHolderNameError: false,

      credentialsMaxMinTicketError: false,

      customerError: false,
    };
  }

  @action
  hasPaymentPageErrors() {
    var paymentPageErrors = Object.entries(toJS(this.paymentPageErrors));
    var errors = [];
    paymentPageErrors.forEach(function (item, index) {
      if (item[1] === true) {
        errors.push(item[0]);
      }
    });
    return errors.length > 0 ? true : false;
  }

  searchingCustomer(value) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    return axios
      .get(
        process.env.REACT_APP_URL_API +
          "GlobalSearch/customers/" +
          entryPoint +
          "?search=" +
          value,
        {
          headers: {
            requestToken: encryptStorage.getItem("pToken"),
          },
          params: {
            limitRecord: "40",
            includeExtendedData: true,
          },
        }
      )
      .then((res) => {
        this.clearCustomer();
        this.clearCustomerSelected();
        let records = res.data.Records;
        let store = this;
        records.forEach(function (item, index) {
          store.addCustomer(item);
        });
      })
      .catch((error) => {
        this.clearCustomerSelected();
        this.clearCustomer();
        throw error;
      });
  }

  searchingVendor(value) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    ).pEntry;

    return axios
      .get(
        process.env.REACT_APP_URL_API +
          "GlobalSearch/vendors/" +
          entryPoint +
          "?search=" +
          value,
        {
          headers: {
            requestToken: encryptStorage.getItem("pToken"),
          },
          params: {
            limitRecord: "40",
          },
        }
      )
      .then((res) => {
        this.clearVendor();
        this.clearVendorSelected();
        let records = res.data.Records;
        let store = this;
        records.forEach(function (item, index) {
          store.addVendor(item);
        });
      })
      .catch((error) => {
        this.clearVendorSelected();
        this.clearVendor();
        throw error;
      });
  }

  searchingVendorNumber(value) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    ).pEntry;

    return axios
      .get(process.env.REACT_APP_URL_API + "Query/vendors/" + entryPoint, {
        headers: {
          requestToken: encryptStorage.getItem("pToken"),
        },
        params: {
          limitRecord: "1",
          "vendorNumber(eq)": value,
        },
      })
      .then((res) => {
        this.clearVendor();
        this.clearVendorSelected();
        let records = res.data.Records;
        let store = this;
        records.forEach(function (item, index) {
          store.addVendor(item);
        });
      })
      .catch((error) => {
        this.clearVendorSelected();
        this.clearVendor();
        throw error;
      });
  }

  searchingLineItems(value) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    return axios
      .get(process.env.REACT_APP_URL_API + "Query/lineitems/" + entryPoint, {
        headers: {
          requestToken: encryptStorage.getItem("pToken"),
        },
        params: {
          limitRecord: "10",
          "name(ct)": value,
        },
      })
      .then((res) => {
        this.clearLineItems();
        this.clearLineItemSelected();
        let records = res.data.Records;
        let store = this;
        records.forEach(function (item, index) {
          store.addLineItem(item);
        });
      })
      .catch((error) => {
        this.clearLineItems();
        this.clearLineItemSelected();
        throw error;
      });
  }

  @action
  selectCustomer(position) {
    //let customer = this.customers[position];
    //delete customer.StoredMethods;
    //delete customer.Subscriptions;
    this.customerSelected = this.customers[position];
  }

  @action
  selectVendor(position) {
    //let customer = this.customers[position];
    //delete customer.StoredMethods;
    //delete customer.Subscriptions;
    this.vendorSelected = this.vendors[position];
  }

  @action
  async getCustomerFromApiById(id) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .get(process.env.REACT_APP_URL_API + "Customer/" + id, {
        headers: {
          requestToken: encryptStorage.getItem("pToken"),
        },
        params: { includeExtendedData: true },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return {};
      });
  }

  @action
  async selectCustomerObject(customer) {
    if (customer?.customerId) {
      try {
        const dataCustomer = await this.getCustomerFromApiById(
          customer.customerId
        );
        this.customerSelected =
          Object.keys(dataCustomer).length > 0 ? dataCustomer : customer;
      } catch (error) {
        this.customerSelected = customer;
      }
    } else {
      this.customerSelected = customer;
    }
    this.disableSearchCustomer = true;
  }

  @action
  selectVendorObject(vendor) {
    //delete customer.StoredMethods;
    //delete customer.Subscriptions;
    this.vendorSelected = vendor;
    this.disableSearchVendor = true;
  }

  @action
  updateCustomerPaymentsOptions() {
    let credentialsPermissions = this.getCredentialsPermissions;

    if (
      this.getCustomerSelected.StoredMethods &&
      this.getCustomerSelected.StoredMethods.length === 0
    ) {
      if (
        this.hasCards &&
        ((credentialsPermissions.card.onetime && !this.isAutopay) ||
          (credentialsPermissions.card.recurring && this.isAutopay))
      ) {
        this.setPaymentMethod("card");
        this.setDefaultPaymentMethodActiveKey("0");
      } else if (
        this.hasECheck &&
        ((credentialsPermissions.ach.onetime && !this.isAutopay) ||
          (credentialsPermissions.ach.recurring && this.isAutopay))
      ) {
        this.setPaymentMethod("ach");
        this.setDefaultPaymentMethodActiveKey("1");
      }
    } else {
      this.setPaymentMethod("savedcard");
      this.setDefaultPaymentMethodActiveKey("2");
    }
  }

  @action
  handleFirstCategoryTextChange(value) {
    this.paymentPage.amount.categories[0].description = value;
  }

  @action
  selectCustomerObjectInSearch(customer) {
    this.customerSelected = customer;
  }

  @action
  selectLineItem(position) {
    let item = toJS(this.lineItems[position]);

    let category = {
      name: item.itemProductName,
      type: "quantity",
      label: item.itemProductName,
      value: parseFloat(item.itemCost).toFixed(2),
      description: item.itemDescription,
      quantity: item.itemQty,
      showDescription: true,
    };

    if (
      this.paymentPage.amount.categories.length === 1 &&
      (parseFloat(this.paymentPage.amount.categories[0].value) === 0 ||
        isNaN(parseFloat(this.paymentPage.amount.categories[0].value)))
    ) {
      this.cleanCategories();
    }

    this.paymentPage.amount.categories.push(category);
  }

  @action
  clearCustomer() {
    this.customers = [];
  }

  @action
  clearVendor() {
    this.vendors = [];
  }

  @action
  clearLineItems() {
    this.lineItems = [];
  }

  @action
  clearCustomerSelected() {
    this.customerSelected = {};
  }

  @action
  clearVendorSelected() {
    this.vendorSelected = {};
  }

  @action
  clearLineItemSelected() {
    this.lineItemSelected = {};
  }

  @action
  addCustomer(customer) {
    this.customers.push(customer);
  }

  @action
  addVendor(vendor) {
    this.vendors.push(vendor);
  }

  @action
  addLineItem(item) {
    delete item.Id;
    delete item.lastUpdated;
    delete item.createdAt;
    this.lineItems.push(item);
  }

  @action
  getPaymentPageFromApi() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    return axios
      .get(
        process.env.REACT_APP_URL_API +
          "Paypoint/" +
          entryPoint +
          "?entrypages=false",
        {
          headers: {
            requestToken: encryptStorage.getItem("pToken"),
          },
        }
      )
      .then((res) => {
        this.setCredentials(
          res.data.responseData.Paypoint
            ? res.data.responseData.Paypoint.Credentials
            : []
        );
        this.setPaypointStatus(
          res?.data?.responseData?.Paypoint?.PaypointStatus || 0
        );
      })
      .catch((error) => {
        throw error;
      });
  }

  @action
  setCredentials(credentials) {
    this.credentials = credentials;
  }

  @action
  setPaypointStatus(value) {
    this.paypointStatus = Number(value);
  }

  @action
  handleAutopayStartDate(date) {
    this.paymentPage.autopay.startDate = date;
  }

  @action
  handleAutopayEndDate(date) {
    this.paymentPage.autopay.finishSelected = date;
  }

  getAutopayEndDate() {
    return this.paymentPage.autopay.finishSelected;
  }

  @action
  handleAutopayEndDateAction(value) {
    this.paymentPage.autopay.finishSelected = value;
  }

  @action
  handleAutopayFrequency(value) {
    this.paymentPage.autopay.frequencySelected = value;
  }

  @action
  updateAutopay(amount, autopay) {
    let data = {};
    data.paymentDetails = autopay.PaymentData.paymentDetails;
    data.paymentDetails.totalAmount = parseFloat(amount);
    data.paymentDetails.categories =
      autopay.PaymentData.paymentDetails.categories;

    data.scheduleDetails = {
      startDate: this.dateStringFormat(this.paymentPage.autopay.startDate),
      endDate: this.getAutopayFinishString(),
      frequency: this.paymentPage.autopay.frequencySelected,
    };

    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .put(
        process.env.REACT_APP_URL_API + "Subscription/" + autopay.IdSub,
        data,
        {
          headers: { requestToken: encryptStorage.getItem("pToken") },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }

  @action
  setCurrency(value) {
    this.currency = value;
  }

  @action
  async getSettingsCurrencyPaypoint() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    const res = await axios.get(
      `${process.env.REACT_APP_URL_API}Paypoint/settings/${entryPoint}`,
      {
        headers: {
          requestToken: encryptStorage.getItem("pToken"),
        },
      }
    );
    let currency = "";
    res.data.general.map(function (item) {
      if (item.key === "currency") {
        currency = item.value;
        return false;
      }
    });
    this.setCurrency(currency);
  }

  @action
  async makePayment(operation) {
    await this.getSettingsCurrencyPaypoint();
    let executeOperation = operation ? operation : "sale";
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    if (this.isAutopay) {
      if (
        this.getPaymentMethod.toLowerCase() === "card" ||
        this.getPaymentMethod.toLowerCase() === "savedcard"
      ) {
        return axios
          .post(
            process.env.REACT_APP_URL_API + "Subscription/add",
            this.paymentCardDataRecurring,
            {
              headers: { requestToken: encryptStorage.getItem("pToken") },
            }
          )
          .then((response) => {
            return response;
          })
          .catch((reason) => {
            throw reason;
          });
      } else if (
        this.getPaymentMethod.toLowerCase() === "ach" ||
        this.getPaymentMethod.toLowerCase() === "savedach"
      ) {
        return axios
          .post(
            process.env.REACT_APP_URL_API + "Subscription/add",
            this.paymentACHDataRecurring,
            {
              headers: { requestToken: encryptStorage.getItem("pToken") },
            }
          )
          .then((response) => {
            return response;
          })
          .catch((reason) => {
            throw reason;
          });
      } else if (this.getPaymentMethod.toLowerCase() === "device") {
      }
    } else {
      if (
        this.getPaymentMethod.toLowerCase() === "card" ||
        this.getPaymentMethod.toLowerCase() === "savedcard"
      ) {
        return axios
          .post(
            process.env.REACT_APP_URL_API +
              (executeOperation === "sale"
                ? "MoneyIn/getpaid"
                : "MoneyIn/authorize"),
            this.paymentCardData,
            {
              headers: { requestToken: encryptStorage.getItem("pToken") },
            }
          )
          .then((response) => {
            if (
              this.paymentPage.paymentMethods.savePaymentMethodForFuture &&
              this.getPaymentMethod.toLowerCase() === "card"
            ) {
              this.addPaymentMethod(this.customerSelected.customerId);
            }
            return response;
          })
          .catch((reason) => {
            throw reason;
          });
      } else if (
        this.getPaymentMethod.toLowerCase() === "ach" ||
        this.getPaymentMethod.toLowerCase() === "savedach"
      ) {
        return axios
          .post(
            process.env.REACT_APP_URL_API +
              (executeOperation === "sale"
                ? "MoneyIn/getpaid"
                : "MoneyIn/authorize"),
            this.paymentACHData,
            {
              headers: { requestToken: encryptStorage.getItem("pToken") },
            }
          )
          .then((response) => {
            if (
              this.paymentPage.paymentMethods.savePaymentMethodForFuture &&
              this.getPaymentMethod.toLowerCase() === "ach"
            ) {
              this.addPaymentMethod(this.customerSelected.customerId);
            }
            return response;
          })
          .catch((reason) => {
            throw reason;
          });
      } else if (this.getPaymentMethod.toLowerCase() === "device") {
        return axios
          .post(
            process.env.REACT_APP_URL_API +
              (executeOperation === "sale"
                ? "MoneyIn/getpaid"
                : "MoneyIn/authorize"),
            this.deviceData,
            {
              headers: { requestToken: encryptStorage.getItem("pToken") },
              timeout: process.env.REACT_APP_VTERMINAL_TIMEOUT * 1000,
            }
          )
          .then((response) => {
            return response;
          })
          .catch((reason) => {
            throw reason;
          });
      } else if (this.getPaymentMethod.toLowerCase() === "check") {
        return axios
          .post(
            process.env.REACT_APP_URL_API +
              (executeOperation === "sale"
                ? "MoneyIn/getpaid"
                : "MoneyIn/authorize"),
            this.checkData,
            {
              headers: { requestToken: encryptStorage.getItem("pToken") },
            }
          )
          .then((response) => {
            return response;
          })
          .catch((reason) => {
            throw reason;
          });
      } else if (this.getPaymentMethod.toLowerCase() === "cash") {
        return axios
          .post(
            process.env.REACT_APP_URL_API +
              (executeOperation === "sale"
                ? "MoneyIn/getpaid"
                : "MoneyIn/authorize"),
            this.cashData,
            {
              headers: { requestToken: encryptStorage.getItem("pToken") },
            }
          )
          .then((response) => {
            return response;
          })
          .catch((reason) => {
            throw reason;
          });
      }
    }
  }

  @computed
  get paymentCardDataRecurring() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    let paymentMethod = null;
    if (this.getPaymentMethod.toLowerCase() === "savedcard") {
      paymentMethod = {
        method: "card",
        storedMethodId: this.paymentPage.paymentMethods.paymentMethodSaved,
      };
    } else {
      paymentMethod = {
        method: "card",
        cardnumber: this.paymentPage.paymentMethods.cardNumber,
        cardexp: this.paymentPage.paymentMethods.cardExpirationDate,
        cardcvv: this.paymentPage.paymentMethods.cardCvv,
        cardzip: this.paymentPage.paymentMethods.cardZipcode,
        cardHolder: this.paymentPage.paymentMethods.cardHolderName,
      };
    }

    let frequency = this.paymentPage.autopay.frequencySelected;
    let startDate = this.dateStringFormat(this.paymentPage.autopay.startDate);
    let endDate = this.getAutopayFinishString();

    if (this.paymentPage.autopay.frequencySelected === "onetime") {
      endDate = startDate;
    }

    let paymentData = {
      entryPoint: entryPoint,
      source: "Virtual Terminal",
      paymentMethod: paymentMethod,
      paymentDetails: {
        totalAmount: Number(
          parseFloat(this.totalAmount.totalAmount).toFixed(2)
        ),
        serviceFee: Number(parseFloat(this.totalAmount.fee).toFixed(2)),
        currency: this.currency,
        categories: this.getCategoriesFieldsToPay(),
      },
      customerData: this.getCustomerSelectedForPay,
      scheduleDetails: {
        startDate: startDate,
        endDate: endDate,
        frequency: frequency,
      },
      invoiceData: {
        invoiceNumber:
          this.paymentPage.payor.invoiceNumber.value &&
          this.paymentPage.payor.invoiceNumber.value !== ""
            ? this.paymentPage.payor.invoiceNumber.value
            : null,
      },
      orderDescription:
        this.paymentPage.notes.value && this.paymentPage.notes.value !== ""
          ? this.paymentPage.notes.value
          : null,
    };
    return paymentData;
  }

  sendReceipt(referenceId) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .get(
        process.env.REACT_APP_URL_API + "MoneyIn/sendreceipt/" + referenceId,
        {
          headers: {
            requestToken: encryptStorage.getItem("pToken"),
          },
        }
      )
      .then((res) => {})
      .catch((error) => {
        throw error;
      });
  }

  @computed
  get paymentACHDataRecurring() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    let paymentMethod = null;
    if (this.getPaymentMethod.toLowerCase() === "savedach") {
      paymentMethod = {
        method: "ach",
        storedMethodId: this.paymentPage.paymentMethods.paymentMethodSaved,
      };
    } else {
      paymentMethod = {
        method: "ach",
        achRouting: this.paymentPage.paymentMethods.achRouting,
        achAccount: this.paymentPage.paymentMethods.achAccount,
        achAccountType: this.paymentPage.paymentMethods.achAccountType,
        achHolder: this.paymentPage.paymentMethods.achAccountHolderName,
      };
    }

    let frequency = this.paymentPage.autopay.frequencySelected;
    let startDate = this.dateStringFormat(this.paymentPage.autopay.startDate);
    let endDate = this.getAutopayFinishString();

    if (this.paymentPage.autopay.frequencySelected === "onetime") {
      endDate = startDate;
    }

    let paymentData = {
      entryPoint: entryPoint,
      //subdomain : this.getPaymentPageSubdomain,
      source: "Virtual Terminal",
      paymentMethod: paymentMethod,
      paymentDetails: {
        totalAmount: Number(
          parseFloat(this.totalAmount.totalAmount).toFixed(2)
        ),
        serviceFee: Number(parseFloat(this.totalAmount.fee).toFixed(2)),
        currency: this.currency,
        categories: this.getCategoriesFieldsToPay(),
      },
      customerData: this.getCustomerSelectedForPay,
      scheduleDetails: {
        startDate: startDate,
        endDate: endDate,
        frequency: frequency,
      },
      invoiceData: {
        invoiceNumber:
          this.paymentPage.payor.invoiceNumber.value &&
          this.paymentPage.payor.invoiceNumber.value !== ""
            ? this.paymentPage.payor.invoiceNumber.value
            : null,
      },
      orderDescription:
        this.paymentPage.notes.value && this.paymentPage.notes.value !== ""
          ? this.paymentPage.notes.value
          : null,
    };
    return paymentData;
  }

  getAutopayFinishString() {
    var date = this.paymentPage.autopay.finishSelected;
    if (!date) return;
    if (typeof date === "string" || date instanceof String) {
      return date;
    } else {
      return this.dateStringFormat(date);
    }
  }

  dateStringFormat(date) {
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;
    return month + "/" + day + "/" + year;
  }

  @computed
  get paymentACHData() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    let paymentMethod = null;
    if (this.getPaymentMethod.toLowerCase() === "savedach") {
      paymentMethod = {
        method: "ach",
        storedMethodId: this.paymentPage.paymentMethods.paymentMethodSaved,
      };
    } else {
      paymentMethod = {
        method: "ach",
        achRouting: this.paymentPage.paymentMethods.achRouting,
        achAccount: this.paymentPage.paymentMethods.achAccount,
        achAccountType: this.paymentPage.paymentMethods.achAccountType,
        achHolder: this.paymentPage.paymentMethods.achAccountHolderName,
      };
    }

    let paymentData = {
      entryPoint: entryPoint,
      source: "Virtual Terminal",
      paymentMethod: paymentMethod,
      paymentDetails: {
        totalAmount: Number(
          parseFloat(this.totalAmount.totalAmount).toFixed(2)
        ),
        serviceFee: Number(parseFloat(this.totalAmount.fee).toFixed(2)),
        currency: this.currency,
        categories: this.getCategoriesFieldsToPay(),
      },
      customerData: this.getCustomerSelectedForPay,
      invoiceData: {
        invoiceNumber:
          this.paymentPage.payor.invoiceNumber.value &&
          this.paymentPage.payor.invoiceNumber.value !== ""
            ? this.paymentPage.payor.invoiceNumber.value
            : null,
      },
      orderDescription:
        this.paymentPage.notes.value && this.paymentPage.notes.value !== ""
          ? this.paymentPage.notes.value
          : null,
    };
    return paymentData;
  }

  @computed
  get paymentCardData() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    let paymentMethod = null;
    if (this.getPaymentMethod.toLowerCase() === "savedcard") {
      paymentMethod = {
        method: "card",
        storedMethodId: this.paymentPage.paymentMethods.paymentMethodSaved,
      };
    } else {
      paymentMethod = {
        method: "card",
        cardnumber: this.paymentPage.paymentMethods.cardNumber,
        cardexp: this.paymentPage.paymentMethods.cardExpirationDate,
        cardcvv: this.paymentPage.paymentMethods.cardCvv,
        cardzip: this.paymentPage.paymentMethods.cardZipcode,
        cardHolder: this.paymentPage.paymentMethods.cardHolderName,
      };
    }

    let paymentData = {
      entryPoint: entryPoint,
      source: "Virtual Terminal",
      paymentMethod: paymentMethod,
      paymentDetails: {
        totalAmount: Number(
          parseFloat(this.totalAmount.totalAmount).toFixed(2)
        ),
        serviceFee: Number(parseFloat(this.totalAmount.fee).toFixed(2)),
        currency: this.currency,
        categories: this.getCategoriesFieldsToPay(),
      },
      customerData: this.getCustomerSelectedForPay,
      invoiceData: {
        invoiceNumber:
          this.paymentPage.payor.invoiceNumber.value &&
          this.paymentPage.payor.invoiceNumber.value !== ""
            ? this.paymentPage.payor.invoiceNumber.value
            : null,
      },
      orderDescription:
        this.paymentPage.notes.value && this.paymentPage.notes.value !== ""
          ? this.paymentPage.notes.value
          : null,
    };
    return paymentData;
  }

  @computed
  get deviceData() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    ).pEntry;

    let paymentMethod = null;
    paymentMethod = {
      method: "cloud",
      device: this.paymentPage.paymentMethods.device,
    };

    let paymentData = {
      entryPoint: entryPoint,
      source: "Virtual Terminal Device",
      paymentMethod: paymentMethod,
      paymentDetails: {
        totalAmount: Number(
          parseFloat(this.totalAmount.totalAmount).toFixed(2)
        ),
        serviceFee: Number(parseFloat(this.totalAmount.fee).toFixed(2)),
        currency: this.currency,
        categories: this.getCategoriesFieldsToPay(),
      },
      customerData: this.getCustomerSelectedForPay,
      invoiceData: {
        invoiceNumber:
          this.paymentPage.payor.invoiceNumber.value &&
          this.paymentPage.payor.invoiceNumber.value !== ""
            ? this.paymentPage.payor.invoiceNumber.value
            : null,
      },
      orderDescription:
        this.paymentPage.notes.value && this.paymentPage.notes.value !== ""
          ? this.paymentPage.notes.value
          : null,
    };
    return paymentData;
  }

  @computed
  get checkData() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    let paymentMethod = null;

    paymentMethod = {
      method: "check",
      achHolder: this.paymentPage.paymentMethods.checkAccountHolderName,
    };

    let paymentData = {
      entryPoint: entryPoint,
      source: "Virtual Terminal",
      paymentMethod: paymentMethod,
      paymentDetails: {
        checkNumber: this.paymentPage.paymentMethods.checkNumber,
        totalAmount: Number(
          parseFloat(this.totalAmount.totalAmount).toFixed(2)
        ),
        serviceFee: Number(parseFloat(this.totalAmount.fee).toFixed(2)),
        currency: this.currency,
        categories: this.getCategoriesFieldsToPay(),
      },
      customerData: this.getCustomerSelectedForPay,
      invoiceData: {
        invoiceNumber:
          this.paymentPage.payor.invoiceNumber.value &&
          this.paymentPage.payor.invoiceNumber.value !== ""
            ? this.paymentPage.payor.invoiceNumber.value
            : null,
      },
      orderDescription:
        this.paymentPage.notes.value && this.paymentPage.notes.value !== ""
          ? this.paymentPage.notes.value
          : null,
    };
    return paymentData;
  }

  @computed
  get cashData() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    let paymentMethod = null;

    paymentMethod = {
      method: "cash",
    };

    let paymentData = {
      entryPoint: entryPoint,
      source: "Virtual Terminal",
      paymentMethod: paymentMethod,
      paymentDetails: {
        checkNumber: this.paymentPage.paymentMethods.checkNumber,
        totalAmount: Number(
          parseFloat(this.totalAmount.totalAmount).toFixed(2)
        ),
        serviceFee: Number(parseFloat(this.totalAmount.fee).toFixed(2)),
        currency: this.currency,
        categories: this.getCategoriesFieldsToPay(),
      },
      customerData: this.getCustomerSelectedForPay,
      invoiceData: {
        invoiceNumber:
          this.paymentPage.payor.invoiceNumber.value &&
          this.paymentPage.payor.invoiceNumber.value !== ""
            ? this.paymentPage.payor.invoiceNumber.value
            : null,
      },
      orderDescription:
        this.paymentPage.notes.value && this.paymentPage.notes.value !== ""
          ? this.paymentPage.notes.value
          : null,
    };
    return paymentData;
  }

  getCategoriesFieldsToPay() {
    var categoryFields = Object.entries(toJS(this.amountCategories));
    var categoryFieldsReturn = [];
    categoryFields.forEach(function (item, index) {
      categoryFieldsReturn.push({
        amount: parseFloat(item[1].value),
        label: item[1].label,
        qty: parseInt(item[1].quantity),
        description: item[1].description,
      });
    });
    return categoryFieldsReturn;
  }

  @computed
  get getCustomerSelected() {
    return this.customerSelected;
  }

  @computed
  get getVendorSelected() {
    return this.vendorSelected;
  }

  @computed
  get hasVendorSelected() {
    return this.vendorSelected !== {};
  }

  @computed
  get getSelectedCustomerString() {
    return (
      (this.customerSelected.Firstname
        ? this.customerSelected.Firstname
        : this.customerSelected.firstname
        ? this.customerSelected.firstname
        : "Firstname") +
      " " +
      (this.customerSelected.Lastname
        ? this.customerSelected.Lastname
        : this.customerSelected.lastname
        ? this.customerSelected.lastname
        : "Lastname") +
      " - " +
      (this.customerSelected.Email
        ? this.customerSelected.Email
        : this.customerSelected.email
        ? this.customerSelected.email
        : "Email")
    );
  }

  @computed
  get getSelectedVendorString() {
    return (
      (this.vendorSelected.Name1
        ? this.vendorSelected.Name1
        : this.vendorSelected.Name1
        ? this.vendorSelected.Name1
        : "") +
      " " +
      (this.vendorSelected.Name2
        ? this.vendorSelected.Name2
        : this.vendorSelected.Name2
        ? this.vendorSelected.Name2
        : "") +
      " - " +
      (this.vendorSelected.Email
        ? this.vendorSelected.Email
        : this.vendorSelected.email
        ? this.vendorSelected.email
        : "Email")
    );
  }

  @computed
  get getCustomerSelectedForPay() {
    if (this.customerSelected && this.customerSelected.customerId) {
      return { customerId: this.customerSelected.customerId };
    } else {
      return {};
    }
  }

  @computed
  get hasCustomerSelected() {
    if (Object.keys(this.customerSelected).length === 0) {
      return false;
    } else {
      return true;
    }
  }

  @computed
  get hasCredentials() {
    if (Object.keys(this.credentials).length === 0) {
      return false;
    } else {
      return true;
    }
  }

  @computed
  get isActivePaypoint() {
    return this.paypointStatus > 0 ? true : false;
  }

  @computed
  get getCredentialsPermissions() {
    let credentials = Object.entries(toJS(this.credentials));

    let availableCredentials = {
      card: { onetime: false, recurring: false, moneyout: false },
      ach: { onetime: false, recurring: false, moneyout: false },
      check: { onetime: false, recurring: false, moneyout: false },
      cash: { onetime: false, recurring: false, moneyout: false },
      wallet: { onetime: false, recurring: false, moneyout: false },
      cloud: { onetime: false, recurring: false, moneyout: false },
      vcard: { onetime: false, recurring: false, moneyout: false },
      managed: { onetime: false, recurring: false, moneyout: false },
    };
    credentials.forEach(function (item, index) {
      switch (item[1].Service.toLowerCase()) {
        case "card":
          if (item[1].Mode === 1) {
            availableCredentials.card.recurring = true;
          } else if (item[1].Mode === 0) {
            availableCredentials.card.onetime = true;
          } else {
            availableCredentials.card.moneyout = true;
          }
          break;
        case "vcard":
          if (item[1].Mode === 1) {
            availableCredentials.vcard.recurring = true;
          } else if (item[1].Mode === 0) {
            availableCredentials.vcard.onetime = true;
          } else {
            availableCredentials.vcard.moneyout = true;
          }
          break;
        case "managed":
          if (item[1].Mode === 1) {
            availableCredentials.managed.recurring = true;
          } else if (item[1].Mode === 0) {
            availableCredentials.managed.onetime = true;
          } else {
            availableCredentials.managed.moneyout = true;
          }
          break;
        case "ach":
          if (item[1].Mode === 1) {
            availableCredentials.ach.recurring = true;
          } else if (item[1].Mode === 0) {
            availableCredentials.ach.onetime = true;
          } else {
            availableCredentials.ach.moneyout = true;
          }
          break;
        case "check":
          if (item[1].Mode === 1) {
            availableCredentials.check.recurring = false;
          } else if (item[1].Mode === 0) {
            availableCredentials.check.onetime = true;
          } else {
            availableCredentials.check.moneyout = true;
          }
          break;
        case "cash":
          if (item[1].Mode === 1) {
            availableCredentials.cash.recurring = false;
          } else if (item[1].Mode === 0) {
            availableCredentials.cash.onetime = true;
          } else {
            availableCredentials.cash.moneyout = true;
          }
          break;
        case "wallet":
          if (item[1].Mode === 1) {
            availableCredentials.wallet.recurring = false;
          } else if (item[1].Mode === 0) {
            availableCredentials.wallet.onetime = true;
          } else {
            availableCredentials.wallet.moneyout = true;
          }
          break;
        case "cloud":
          if (item[1].Mode === 1) {
            availableCredentials.cloud.recurring = true;
          } else if (item[1].Mode === 0) {
            availableCredentials.cloud.onetime = true;
          } else {
            availableCredentials.cloud.moneyout = true;
          }
          break;
        default:
          break;
      }
    });
    return availableCredentials;
  }

  @computed
  get getPaymentDataStoredId() {
    return this.paymentDataStoredId;
  }

  @action
  validateCredentialsMaxMinTicketError() {
    var categories = Object.entries(toJS(this.paymentPage.amount.categories));
    let credentials = Object.entries(toJS(this.credentials));
    let paymentMethod = this.getPaymentMethod;
    let netAmount = 0;
    let fee = 0;
    let objStore = this;

    categories.forEach(function (item, index) {
      if (item[1].optionalPay === true && !item[1].optionalPayChecked) {
        return;
      }
      netAmount =
        netAmount + parseFloat(item[1].value) * parseFloat(item[1].quantity);
    });

    credentials.forEach(function (item, index) {
      let isAutopayVar = objStore.isAutopay ? 1 : 0;
      if (
        (paymentMethod.toLowerCase() === "card" ||
          paymentMethod.toLowerCase() === "savedcard") &&
        item[1].Service.toLowerCase() === "card" &&
        item[1].Mode === isAutopayVar
      ) {
        fee =
          item[1].CfeeFix + (netAmount * parseFloat(item[1].CfeeFloat)) / 100;
        if (
          fee + netAmount > item[1].Maxticket ||
          fee + netAmount < item[1].Minticket
        ) {
          objStore.setPaymentPageError("credentialsMaxMinTicketError", true);
        } else {
          objStore.setPaymentPageError("credentialsMaxMinTicketError", false);
        }

        return;
      }

      if (
        (paymentMethod.toLowerCase() === "ach" ||
          paymentMethod.toLowerCase() === "savedach") &&
        item[1].Service.toLowerCase() === "ach" &&
        item[1].Mode === isAutopayVar
      ) {
        fee =
          item[1].CfeeFix + (netAmount * parseFloat(item[1].CfeeFloat)) / 100;

        if (
          fee + netAmount > item[1].Maxticket ||
          fee + netAmount < item[1].Minticket
        ) {
          objStore.setPaymentPageError("credentialsMaxMinTicketError", true);
        } else {
          objStore.setPaymentPageError("credentialsMaxMinTicketError", false);
        }

        return;
      }
    });
  }

  getRoundUpDecimal2(pNumber) {
    let numberFloat = parseFloat(pNumber);
    return Math.ceil(numberFloat * 100) / 100;
  }

  @computed
  get totalAmount() {
    var categories = Object.entries(toJS(this.paymentPage.amount.categories));
    var credentials = Object.entries(toJS(this.credentials));
    var paymentMethod = this.getPaymentMethod;
    var netAmount = 0;
    var fee = 0;
    var objStore = this;

    categories.forEach(function (item, index) {
      if (item[1].optionalPay === true && !item[1].optionalPayChecked) {
        return;
      }
      netAmount =
        netAmount + parseFloat(item[1].value) * parseFloat(item[1].quantity);
    });

    //Calculating fee
    credentials.forEach(function (item, index) {
      let isAutopayVar = objStore.isAutopay ? 1 : 0;
      if (
        (paymentMethod.toLowerCase() === "card" ||
          paymentMethod.toLowerCase() === "savedcard") &&
        item[1].Service.toLowerCase() === "card" &&
        item[1].Mode === isAutopayVar
      ) {
        fee =
          item[1].CfeeFix +
          objStore.getRoundUpDecimal2(
            (netAmount * parseFloat(item[1].CfeeFloat)) / 100
          );
        if (item[1].CfeeMin !== 0 && fee < item[1].CfeeMin) {
          fee = item[1].CfeeMin;
        }
        if (item[1].CfeeMax !== 0 && fee > item[1].CfeeMax) {
          fee = item[1].CfeeMax;
        }
        return;
      }

      if (
        (paymentMethod.toLowerCase() === "ach" ||
          paymentMethod.toLowerCase() === "savedach") &&
        item[1].Service.toLowerCase() === "ach" &&
        item[1].Mode === isAutopayVar
      ) {
        fee =
          item[1].CfeeFix +
          objStore.getRoundUpDecimal2(
            (netAmount * parseFloat(item[1].CfeeFloat)) / 100
          );
        if (item[1].CfeeMin !== 0 && fee < item[1].CfeeMin) {
          fee = item[1].CfeeMin;
        }
        if (item[1].CfeeMax !== 0 && fee > item[1].CfeeMax) {
          fee = item[1].CfeeMax;
        }
        return;
      }

      if (
        paymentMethod.toLowerCase() === "device" &&
        item[1].Service.toLowerCase() === "wallet" &&
        item[1].Mode === isAutopayVar
      ) {
        fee =
          item[1].CfeeFix +
          objStore.getRoundUpDecimal2(
            (netAmount * parseFloat(item[1].CfeeFloat)) / 100
          );
        if (item[1].CfeeMin !== 0 && fee < item[1].CfeeMin) {
          fee = item[1].CfeeMin;
        }
        if (item[1].CfeeMax !== 0 && fee > item[1].CfeeMax) {
          fee = item[1].CfeeMax;
        }
        return;
      }

      if (
        paymentMethod.toLowerCase() === "device" &&
        item[1].Service.toLowerCase() === "cloud" &&
        item[1].Mode === isAutopayVar
      ) {
        fee =
          item[1].CfeeFix +
          objStore.getRoundUpDecimal2(
            (netAmount * parseFloat(item[1].CfeeFloat)) / 100
          );
        if (item[1].CfeeMin !== 0 && fee < item[1].CfeeMin) {
          fee = item[1].CfeeMin;
        }
        if (item[1].CfeeMax !== 0 && fee > item[1].CfeeMax) {
          fee = item[1].CfeeMax;
        }
        return;
      }
    });

    netAmount = isNaN(netAmount) ? 0 : netAmount;
    fee = isNaN(fee) ? 0 : fee;

    var total = {
      netAmount: netAmount,
      fee: fee,
      totalAmount: netAmount + fee,
    };
    return total;
  }

  @computed
  get getPaymentMethod() {
    return this.paymentMethod;
  }

  @action
  deletePaymentMethod(methodId) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .delete(process.env.REACT_APP_URL_API + "TokenStorage/" + methodId, {
        headers: { requestToken: encryptStorage.getItem("pToken") },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }

  @action
  addPaymentMethod(customerId) {
    let paymentData = null;

    if (this.isAutopay) {
      if (this.getPaymentMethod.toLowerCase() === "card") {
        paymentData = this.paymentCardDataRecurring;
      } else if (this.getPaymentMethod.toLowerCase() === "ach") {
        paymentData = this.paymentACHDataRecurring;
      }
    } else {
      if (this.getPaymentMethod.toLowerCase() === "card") {
        paymentData = this.paymentCardData;
      } else if (this.getPaymentMethod.toLowerCase() === "ach") {
        paymentData = this.paymentACHData;
      }
    }

    let payorData = { customerId: parseInt(customerId) };
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    return axios
      .post(
        process.env.REACT_APP_URL_API + "TokenStorage/add",
        {
          paymentMethod: paymentData.paymentMethod,
          customerData: payorData,
          entryPoint: entryPoint,
          fallbackAuth: true
        },
        {
          headers: { requestToken: encryptStorage.getItem("pToken") },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }
}

const virtualTerminalStore = new VirtualTerminalStore();
export default virtualTerminalStore;
