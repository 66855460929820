import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../components/Layout';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PayabliStorageManager } from '../../api/localStorageManager'
import { BiChevronLeft, BiLowVision, BiShowAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { IMaskInput } from 'react-imask';
import zxcvbn from 'zxcvbn';
import PhoneInput from '../../components/PhoneInput';

@inject('user', 'global')
@observer
class NewUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            status: '',
            username: '',
            password: '',
            repeatPassword: '',
            timeZone: 0,
            score: 0,
            profileId: null,
            showPassword: false,
            showRepeatPassword: false,

            userErrors :{},
            resetPasswordEmail: '',
            step: 1,
            permissions : [],
            userPermissions : [],
            selectedPermissions : [],

            enableMFA: false,
            enableMFASaved: false,
            enableMFAType: '0',
            enableMFAError: false,
            enableMFAQRCodeAuth: null,
            enableMFAQRCodeManual: '',
            disableSaveButton: false
        };

        this.handleTextChangeMask = this.handleTextChangeMask.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.selectTimezone = this.selectTimezone.bind(this);
        this.goToPermissions = this.goToPermissions.bind(this);
        this.goTo = this.goTo.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.showPassword = this.showPassword.bind(this);
        this.showRepeatPassword = this.showRepeatPassword.bind(this);
        this.getUserFromApi = this.getUserFromApi.bind(this);
        this.showError = this.showError.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.handlePermissions = this.handlePermissions.bind(this);
        this.checkChildrens = this.checkChildrens.bind(this);
        this.componentPhone = React.createRef();
    }

    checkChildrens(permission,checked){
        if(permission.childs){
             permission.childs.forEach(function (secondLevel) {
                 secondLevel.checked = checked;
                 if(secondLevel.childs){
                     secondLevel.childs.forEach(function (thirdLevel) {
                         thirdLevel.checked = checked;
                     });
                 } 
             });
         }
         return permission;
     }

    componentDidMount() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        this.props.global.setLoading(true);
        let profileId = this.props.match.params.id;
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];;

        let selectedPermissions = [];
        userPermissions.forEach(function (item) {
            selectedPermissions.push({
                "roleLabel": item,
                "roleValue": false
            });
        });
        let timeZone = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).timeZone ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).timeZone : 0;

        this.setState({
            profileId: profileId, 
            userPermissions: userPermissions, 
            selectedPermissions: selectedPermissions, 
            timeZone:timeZone
        });
        this.getUserFromApi(profileId);
    }

    showError(error){
        let errorMessage = (error.response && error.response.data && error.response.data.responseData && error.response.data.responseData.explanation) ? (error.response.data.responseData.explanation) : (error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!");

        toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
        });
    }
    
    showSuccess(Message){
        toast.success(Message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-success-container',
        });
    }

    hasMFAFieldsErrors(){
        return this.state.enableMFA === true && this.state.enableMFAType === '' ? true : false;
    }

    saveUser(){
        
        let errors = this.validateUserFields();
        this.setState({ userErrors: errors, enableMFAError : this.hasMFAFieldsErrors() }, function(){
            
            if(Object.keys(errors).length === 0 && !this.state.enableMFAError){
                let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
                let paypointId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).paypointId;

                let user ={
                    name: this.state.name,
                    phone: this.state.phone,
                    email: this.state.username,
                    usrStatus: this.state.status,
                    pwd: this.state.password,
                    timeZone: this.state.timeZone,
                    scope: [{orgType:2, orgId: paypointId}],
                    access: this.state.selectedPermissions,
                    mfa: this.state.enableMFA,
                    mfaMode: parseInt(this.state.enableMFAType)
                };
                
                this.props.global.setLoading(true);   
                this.props.user.newUserInfo(user)
                .then(result => {
                    this.props.global.setLoading(false);
                    this.showSuccess("User created successfully!")
                    let thisObj = this;
                    setTimeout(function(){
                        thisObj.props.history.push("/"+PayabliStorageManager.getEntryName()+"/report/users");
                    }, 3500);
                    this.setState({
                        disableSaveButton: true
                    });
                    
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    this.showError(error)
                });
            }
        });
    }

    validateUserFields(){
        let validators = this.props.global.validators;
        let errors = {};
        if(validators.isEmpty(this.state.name) || validators.isMaxLength(250, this.state.name))
        {
            errors['name']= true;
        }
        
        if(validators.isEmpty(this.state.username) || validators.isMaxLength(250, this.state.username) || validators.stringValidator('email',this.state.username))
        {
            errors['username']= true;
        }

        let userPhone = this.state.phone;
        userPhone = userPhone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');

        if(this.componentPhone?.current?.hasError() || validators.stringValidator('phone', userPhone))
        {
            errors['phone']= true;
        }

        if(validators.isEmpty(this.state.status))
        {
            errors['status']= true;
        }

        if(validators.isEmpty(this.state.password) || validators.isMaxLength(250, this.state.password) || (this.state.score < 4))
        {
            errors['password']= true;
        }

        if(this.state.password !== this.state.repeatPassword || validators.isEmpty(this.state.repeatPassword) || validators.isMaxLength(250, this.state.repeatPassword))
        {
            errors['repeatPassword']= true;
        }
        this.setState({userFormErrors : errors})
        return errors;
    }

    goTo(x){
        this.setState({step: x === 0 ? 1 : x});
    }

    goToPermissions(){
        let errors = this.validateUserFields();
        this.setState({ userErrors: errors }, function(){
            
            if(Object.keys(errors).length === 0){
                this.goTo(2);
            }
        });
    }

    handleTextChange(e){
        this.setState({ [e.target.id]: e.target.value }, function(){
            let testedResult = zxcvbn(this.state.password);
            this.setState({score : testedResult.score});
        });
    }

    handleTextChangeMask(e, value){
        this.setState({ [e]: value });
    }

    selectTimezone(e){
        if(e){
            this.setState({timeZone:e.target.value})
        }
    }

    showPassword(){
        this.setState({ showPassword:!this.state.showPassword });
    }

    showRepeatPassword(){
        this.setState({ showRepeatPassword:!this.state.showRepeatPassword });
    }

    handleEnableMFA(e){
        this.setState({enableMFA:e.target.checked});
    }

    handleCheckMFA(e){
        this.setState({enableMFAType:e.target.value});
    }

    handlePermissions(obj, i, e, f, parent ){
        let permissions = this.state.permissions;
        let selectedPermissions = this.state.selectedPermissions;
        let countPermissions = this.state.countPermissions;
        let countPermissionsChecked = this.state.countPermissionsChecked;
        let object = permissions[i];

        if(i!==null && e!==null && f!==null){
            permissions[i].childs[e].childs[f].checked = obj.target.checked;
            object = permissions[i].childs[e].childs[f];
            
        }else if(i!==null && e!==null){
            permissions[i].childs[e].checked = obj.target.checked;
            object = permissions[i].childs[e];
            
        }else if(i!==null){
            permissions[i].checked = obj.target.checked;
            
            permissions[i] = this.checkChildrens(permissions[i], obj.target.checked);
            if(obj.target.checked){
                countPermissionsChecked[permissions[i].permission] = countPermissions[permissions[i].permission];
            }
            else{
                countPermissionsChecked[permissions[i].permission] = 0;
            }
        }

        //checkboxes dependency
        if(object.requiredBy){
            if(object.checked){
                this.checkRequired(object.requiredBy);
            }
        }

        if(parent){
            if(obj.target.checked){
                countPermissionsChecked[parent] = countPermissionsChecked[parent] + 1;
            }
            else{
                countPermissionsChecked[parent] = countPermissionsChecked[parent] - 1;
            }
    
            if(countPermissionsChecked[parent] === 0){
                permissions[i].checked = false;
            }else{
                permissions[i].checked = true;
            }
        }


        selectedPermissions.forEach(function(permission){
            if(object.permission === permission.roleLabel){
                permission.roleValue = object.checked;
                if(object.childs){
                    object.childs.forEach(function(objectSecondLevel){
                        selectedPermissions.forEach(function(permissionSecondLevel){
                            if(objectSecondLevel.permission === permissionSecondLevel.roleLabel){
                                permissionSecondLevel.roleValue = object.checked;
                                if(objectSecondLevel.childs){
                                    objectSecondLevel.childs.forEach(function(objectThirdLevel){
                                        selectedPermissions.forEach(function(permissionThirdLevel){
                                            if(objectThirdLevel.permission === permissionThirdLevel.roleLabel){
                                                permissionThirdLevel.roleValue = object.checked;
                                            }
                                        });
                                    });
                                }
                            }
                        });
                    });
                }
            }
        });
    

        this.setState({ permissions: permissions, countPermissionsChecked: countPermissionsChecked, selectedPermissions : selectedPermissions });
    }

    checkSelectedPermission(key, value, scope){
        let selectedPermissions = this.state.selectedPermissions;
        selectedPermissions.forEach(function(permission){
            if(permission.roleLabel === key){
                if(scope === 1){
                    permission.roleValue = false;
                }else{
                    permission.roleValue = value;
                }
            }
        });
        this.setState({selectedPermissions:selectedPermissions});
    }

    getUserFromApi(){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];;
        let profileId = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').id ? encryptStorage.getItem('pUser').id : null;

        let thisObj = this;
        let entry = this.props.match.params.entryName;

        if(profileId)
        {
                this.props.user.getUserFromApi(profileId, entry).then(res => {
                
                    this.props.user.getPermissionsFromApi().then(permissions => {
                        let permissionsDictionary = [];
                        if(res.access){
                            res.access.forEach(function (item) {
                                permissionsDictionary[item.roleLabel] = item.roleValue
                            });
                        }
                        
                        let countPermissionsChecked = [];
                        let countPermissions = [];
                        
                        permissions.forEach(function (item) {
                            item.checked = permissionsDictionary[item.permission];
                            thisObj.checkSelectedPermission(item.permission, item.checked, item.scope)
                            countPermissions[item.permission] = 0;
                            countPermissionsChecked[item.permission] = 0;

                            if(item.childs){
                                item.childs.forEach(function (secondLevel) {
                                    secondLevel.checked = permissionsDictionary[secondLevel.permission];
                                    thisObj.checkSelectedPermission(secondLevel.permission, secondLevel.checked, secondLevel.scope)
                                    secondLevel.parent = item.permission;

                                    if(userPermissions.indexOf(secondLevel.permission) !== -1)
                                    {
                                        countPermissions[item.permission] = countPermissions[item.permission] + 1;
                                        countPermissionsChecked[item.permission] = secondLevel.checked ? countPermissionsChecked[item.permission] + 1 : countPermissionsChecked[item.permission];
                                    }

                                    if(secondLevel.childs){
                                        secondLevel.childs.forEach(function (thirdLevel) {
                                            thirdLevel.checked = permissionsDictionary[thirdLevel.permission];
                                            thisObj.checkSelectedPermission(thirdLevel.permission, thirdLevel.checked, thirdLevel.scope)
                                            thirdLevel.parent = item.permission;
                                            
                                            if(userPermissions.indexOf(thirdLevel.permission) !== -1)
                                            {
                                                countPermissions[item.permission] = countPermissions[item.permission] + 1;
                                                countPermissionsChecked[item.permission] = thirdLevel.checked ? countPermissionsChecked[item.permission] + 1 : countPermissionsChecked[item.permission];
                                            }

                                        });
                                    } 
                                });
                            }
                            
                        });
                        let permissionsEnabled = JSON.parse(JSON.stringify(permissions));

                        this.setState({permissions: permissions,permissionsEnabled: permissionsEnabled,countPermissions:countPermissions,countPermissionsChecked:countPermissionsChecked });
                        this.props.global.setLoading(false);
                    }).catch(error => {
                        this.showError(error);
                        this.props.global.setLoading(false);
                    });
            
            })
            .catch(error => {
                this.showError(error);
                this.props.global.setLoading(false);
            });
        }
       

    }
   
    render() {
        let reactObject = this;

        return (
            <Layout {...this.props}>
            <div>
                <TopBar>
                <MainTopBarMenu />
                <div className="top-bar-sub">
                    <div className="row datatable-actions">
                        <div className="col-12" style={{ alignSelf: 'center' }}>
                       
                        <Link to={"/" + this.props.global.getURLEntry() + "/report/users"} className="btn btn-light text-transform-normal">
                            <BiChevronLeft /> Go back to user list
                        </Link>
                        
                        </div>
                        <div className="col-7 text-right">
                           
                        </div>
                    </div>
                    </div>
                </TopBar>


                <div className="mt-body4">
                    <div className="row">
                        <div className="col-lg-8" style={{maxWidth: "700px"}}>
                            <div className="mb-3">
                            <h5 className='mb-5'>Add User</h5>


                            <div className="progress mb-4">
                                <div className="progress-bar" role="progressbar" style={{width: this.state.step === 1 ? "33%" :this.state.step === 2 ? "66%" : "100%", backgroundColor: "#10A0E3"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{"Step "+this.state.step+ " of 3"}</div>
                            </div>
                            
                            {this.state.step === 1 &&
                            <div className="row">
                                <h5>User Information</h5>
                                <p className="small-grey mt-0 mb-4">Fill the form with user information.</p>
                                <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <input  value={this.state.name} id="name" onChange={(e) => this.handleTextChange(e)} className={this.state.userErrors.name ? "form-control input-error" : "form-control"} placeholder="Full Name" />
                                    <label htmlFor="name">Full Name</label>
                                </div>
                                </div>

                                <div className={"col-6 " + (this.state?.userFormErrors?.phone === true ? "input-error-container" : "")}>
                                    <PhoneInput
                                        ref={this.componentPhone}
                                        iTitle={"Phone"}
                                        iValue={ this.state.phone || '' }
                                        iCustomClass="inner-addon"
                                        iPlaceholder="Phone"
                                        iMask="phone"
                                        iOnChange={this.handleTextChangeMask}
                                        iOnChangeForce={this.handleTextChangeMask}
                                        iName="phone"
                                        full
                                    />
                                </div>

                                <div className="col-6  mb-3">
                                <div className="form-floating">                                
                                    <IMaskInput
                                        mask={this.props.global.maskValidator('email')}
                                        name="username"
                                        unmask={true}
                                        value={this.state.username}                                        
                                        onAccept={
                                            (value, mask) => this.handleTextChangeMask('username', value)
                                        }
                                        placeholder="Email"
                                        className={this.state.userErrors.username ? "form-control input-error" : "form-control"}
                                        id="username"
                                    />
                                    <label htmlFor="username">Email</label>
                                </div>
                                </div>

                                <div className="col-6 mb-3">
                                    <select className="form-select form-control" disabled>
                                        <option value="" defaultValue>English</option>
                                    </select>
                                </div>

                                <div className="col-6 mb-3">
                                    <div className="form-floating">
                                    <select id="timezone"  className="form-select form-control" onChange={(e)=> this.selectTimezone(e)} value={this.state.timeZone}>
                                        <option value="0">UTC</option>
                                        <option value="-4">Atlantic Time UTC−04:00</option>
                                        <option value="-5">Eastern Time UTC−05:00</option>
                                        <option value="-6">Central Time UTC−06:00</option>
                                        <option value="-7">Mountain Time UTC−07:00</option>
                                        <option value="-8">Pacific Time UTC−08:00</option>
                                        <option value="-9">Alaskan Time UTC−09:00</option>
                                        <option value="-10">Hawaii-Aleutian Time UTC−10:00</option>
                                    </select>
                                    <label htmlFor="timezone">Time zone</label>
                                    </div>
                                </div>

                                <div className="col-12 mb-3">
                                    <select className={this.state.userErrors.status ? "form-select form-control input-error" : "form-select form-control"} id="status" onChange={(e) => this.handleTextChange(e)} value={ this.state.status}>
                                        <option value="">Status</option>
                                        <option value={1}>Active</option>
                                        <option value={0}>Inactive</option>
                                        <option value={85}>Locked</option>
                                    </select>
                                </div>

                                <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <input value={this.state.password} type={ this.state.showPassword ? "text" : "password"} className={this.state.userErrors.password ? "form-control input-error" : "form-control"} id="password" onChange={(e) => this.handleTextChange(e)} placeholder="Password" />
                                    <label htmlFor="password">Password</label>
                                    {
                                        this.state.showPassword ? 
                                        <BiLowVision className="right-icon-on-input" onClick={(e)=>this.showPassword()}/> 
                                        :
                                        <BiShowAlt className="right-icon-on-input" onClick={(e)=>this.showPassword()}/>
                                    }
                                </div>
                                </div>

                                <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <input value={this.state.repeatPassword} type={ this.state.showRepeatPassword ? "text" : "password"} className={this.state.userErrors.repeatPassword ? "form-control input-error" : "form-control"} id="repeatPassword" onChange={(e) => this.handleTextChange(e)}  placeholder="Repeat password" />
                                    <label htmlFor="repeatPassword">Repeat password</label>
                                    {
                                        this.state.showRepeatPassword ? 
                                        <BiLowVision className="right-icon-on-input" onClick={(e)=>this.showRepeatPassword()}/> 
                                        :
                                        <BiShowAlt className="right-icon-on-input" onClick={(e)=>this.showRepeatPassword()}/>
                                    }
                                </div>
                                </div>

                                <div className="col-12 text-center">
                                <p className="small">The password must be <b>Strong</b> to be valid</p>
                                <progress value={this.state.score} max="4" className={"mb-1 password-strength-meter-progress strength-"+this.props.global.createPasswordLabel(this.state.score)}/>
                                <p className="mb-4 small">Password is <b>{this.props.global.createPasswordLabel(this.state.score)}</b></p>
                                </div>

                            </div>
                            }


                            {this.state.step === 2 &&
                            <div className="row">
                                 <h5>User Permissions</h5>
                                <p className="small-grey mt-0 mb-4">select the privileges that this user can have.</p>
                                   {
                                    <div className="row">
                                        {
                                        this.state.permissions.map((record, i) => (

                                            (this.state.userPermissions.indexOf(record.permission) !== -1 && record.scope !== 1)&&
                                            <div key={i} className="col-4 mb-3">
                                            <div className="mb-3">
                                            <div className="icheck-primary">
                                                <input checked={ record.checked ? true : false } type="checkbox" id={i} onChange={(obj) => reactObject.handlePermissions(obj, i, null, null)} />
                                                <label htmlFor={i}><b className="small-small">{record.permission}</b></label>
                                            </div>
                                            </div>

                                                {
                                                    record.childs &&
                                                    record.childs.map((child1, e )=> (

                                                        (this.state.userPermissions.indexOf(child1.permission) !== -1 && child1.scope !== 1)&&
                                                        child1.permission !== "Profile" &&
                                                        <div key={i+"second"+e}>
                                                        <div className="mb-3">
                                                        <div className="icheck-primary">
                                                            <input readOnly checked={ child1.checked ? true : false } type="checkbox" id={i+"second"+e} onChange={(obj) => reactObject.handlePermissions(obj, i, e, null, child1.parent)}/>
                                                            <label className="small-small" htmlFor={i+"second"+e}>{child1.permission}</label>
                                                        </div>
                                                        </div>

                                                        {
                                                            child1.childs &&
                                                            child1.childs.map((child2, f )=> (

                                                                (this.state.userPermissions.indexOf(child2.permission) !== -1 && child2.scope !== 1)&&
                                                                <div key={e+"third"+f} className="mb-3">
                                                                <div className="icheck-primary">
                                                                    <input readOnly checked={ child2.checked ? true : false } type="checkbox" id={e+"third"+f} onChange={(obj) => reactObject.handlePermissions(obj, i, e, f ,child2.parent)}/>
                                                                    <label className="small-small" htmlFor={e+"third"+f}>{child2.permission}</label>
                                                                </div>
                                                                </div>
                                                            ))
                                                        }
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        ))
                                            }
                                        </div>
                                    }
                            </div>
                            }

                            

                            {this.state.step === 3 &&
                            <div className="row">
                                <h5>Edit multi-factor authentication</h5>
                                <p className="small-grey mt-0 mb-4">Enable multi-factor authentication to protect your security.</p>
                                <div className="mb-4">
                            <div className="mb-3">
                                <div className="icheck-primary">
                                    <input name="mfaActivate" id="mfaActivate" onChange={(e) => this.handleEnableMFA(e)} type="checkbox" checked={this.state.enableMFA} />
                                    <label htmlFor="mfaActivate"><b>Enable multi-factor authentication (MFA)</b></label>
                                </div>
                            </div>

                            <p className="small-small">
                                To protect your security, we will provide you with a security code to confirm your identity. You will use this code once, to complete your login.
                            </p>
                            <p className="small-small">
                                How would you like to receive this security code?
                            </p>
                            { (this.state.enableMFAError && this.state.enableMFA) &&
                                <p className="small-small text-danger">Please select at least one</p>
                            }

                            <div className="row">
                                <div className="col-sm-7">
                                    <div className="icheck-primary">
                                        <input
                                            name="enableMFAType"
                                            type="radio"
                                            id="textMessageMFAModal"
                                            disabled={(!this.state.enableMFA || this.state.phone === '')}
                                            value="2"
                                            checked={this.state.enableMFAType === '2' ? true : false}
                                            onChange={(e)=> this.handleCheckMFA(e)}
                                        />
                                        <label htmlFor="textMessageMFAModal">
                                            Text Message<br/>
                                            <span className="small-small">Phone #: { this.state.phone ? this.state.phone: "Edit your profile to set a phone #" }</span>
                                        </label>
                                    </div>

                                    <div className="icheck-primary">
                                    <input
                                        name="enableMFAType"
                                        type="radio"
                                        id="emailMFAModal"
                                        disabled={!this.state.enableMFA}
                                        value="0"
                                        checked={this.state.enableMFAType === '0' ? true : false}
                                        onChange={(e)=> this.handleCheckMFA(e)}
                                    />
                                    <label htmlFor="emailMFAModal">
                                        Email<br/>
                                        <span className="small-small">Email Address: { this.state.username ? this.state.username: "-" }</span>
                                    </label>
                                    </div>



                                </div>
                                <div className="col-sm-5 text-center">
                                    { this.state.enableMFAQRCodeAuth &&
                                    <>
                                        <img alt="" style={{width: "100%"}} src={this.state.enableMFAQRCodeAuth} />
                                        {this.state.enableMFAQRCodeManual !== '' &&
                                            <p className="small-small">{this.state.enableMFAQRCodeManual}</p>
                                        }
                                    </>
                                    }
                                </div>
                            </div>

                   
                        </div>
                            </div>
                            }




                            <div className="row">
                                <div className="col">
                                    <button disabled={this.state.step === 1 || this.state.disableSaveButton ? true : false} className="btn full-w btn-light" type="button" onClick={() => this.goTo(this.state.step - 1) }>Back Step</button>
                                </div>
                                <div className="col">
                                <button disabled={this.state.disableSaveButton} className="btn full-w btn-primary" type="button" onClick={() => this.state.step === 1 ? this.goToPermissions() : this.state.step === 2 ? this.goTo(3) : this.saveUser()}>{this.state.step < 3 ? "Next Step" : "Save"}</button>   
                                </div>
                            </div>  

                        </div>
                        </div>
                    </div>
                </div>

                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { NewUser };